import styles from "./button.module.css";

const Button = ({href, text, type, style, press}) => {

  return (
    <a style={style} href={href} className={`${styles.btn} ${styles[type]}`} onClick={press} >
      <span>{text}</span>
    </a>
  )
}

export default Button;