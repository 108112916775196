import styles from "./footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div>
        <h2>FOCOLOR <span>SERVICE</span></h2>
        <p>Надежный автосервис для вашего комфорта и безопасности. Профессиональные решения для вашего автомобиля от нашей команды экспертов.</p>
      </div>
      <div>
        <p>ООО "Фоколор-сервис"</p>
        <a href="tel:+375291524379">
          +375 (29) 152-43-79
        </a>
        <a href="https://yandex.com/maps/org/fokolor_servis/188536940237/?ll=27.513412%2C53.907206&mode=search&sctx=ZAAAAAgBEAAaKAoSCfFmDd5XtSVAEXmxMEROl0hAEhIJ9N4YAoCNX0ARwFlKlpNUSEAiBgABAgMEBSgKOABAkE5IAWIecmVsZXZfc2VydmljZV9hcmVhX3BlcmNlbnQ9MTAwagJydZ0BzcxMPaABAKgBAL0BCvMGM8IBBs3Nta2%2BBYICG9GE0L7QutC%2B0LvQvtGAINGB0LXRgNCy0LjRgYoCAJICAJoCDGRlc2t0b3AtbWFwcw%3D%3D&sll=27.513412%2C53.907206&sspn=0.015407%2C0.005315&text=%D1%84%D0%BE%D0%BA%D0%BE%D0%BB%D0%BE%D1%80%20%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81&z=17">
          Минск, Кальварийская ул., 33/13
        </a>
      </div>
    </footer>
  )
}

export default Footer;