import ServiceItem from "./serviceItem";
import { MdComputer } from "react-icons/md";
import { AiOutlineFieldBinary } from "react-icons/ai";
import { RiOilLine } from "react-icons/ri";
import { PiEngine } from "react-icons/pi";
import { MdCarRepair } from "react-icons/md";
import { TbTemperatureCelsius } from "react-icons/tb";
import styles from "./services.module.css";

const Services = () => {
  const services = [
    {icon: <MdComputer />, title: "Компьютерная диагностика", text: "Как показывает практика, когда не заводится машина, в первую очередь нужно выяснить какая система дала сбой при помощи компьютерной диагностики. От этого зависит дальнейший план действий, направленный на обнаружение и устранение дефекта."},
    {icon: <div><AiOutlineFieldBinary /><AiOutlineFieldBinary /></div>, title: "Программирование авто", text: "Мы предлагаем широкий спектр услуг по программированию автомобилей. Наши специалисты обеспечат настройку и обновление программного обеспечения вашего авто, гарантируя его оптимальную работу."},
    {icon: <RiOilLine />, title: "Плановое обслуживание", text: "Надежное и эффективное плановое обслуживание вашего автомобиля. Включает в себя проверку и замену масла, фильтров, тормозной системы и других важных компонентов для поддержания оптимальной работы вашего транспортного средства."},
    {icon: <PiEngine />, title: "Ремонт двигателя", text: "Профессиональный ремонт двигателя вашего автомобиля. Наши опытные специалисты обеспечат диагностику, ремонт и обслуживание двигателя, гарантируя его надежную работу и долговечность."},
    {icon: <MdCarRepair />, title: "Ремонт подвески", text: "Эффективный ремонт подвески вашего автомобиля. Наши специалисты осуществляют комплексную диагностику и профессиональное восстановление подвесочных компонентов, обеспечивая комфорт и безопасность на дороге."},
    {icon: <TbTemperatureCelsius />, title: "Ремонт сис-мы охлаждения", text: "Качественный ремонт системы охлаждения вашего автомобиля. Наши опытные механики обеспечат диагностику и восстановление системы охлаждения, гарантируя оптимальную температуру работы двигателя и предотвращая перегрев."},

  ];

  return (
    <section id="services" className={styles.services}>
      <h2>Предоставляемые услуги</h2>
      <div className={styles.list}>
        {
          services.map((el) => <ServiceItem key={el.title} icon={el.icon} title={el.title} text={el.text} />)
        }
      </div>
    </section>
  )
} 

export default Services;