import { useState } from "react";
import styles from "./services.module.css";

const ServiceItem = ({icon, title, text}) => {
  const [isHover, setIsHover] = useState(false);
  const styleNames = isHover ? `${styles.item} ${styles.active}` : `${styles.item}`;

  return (
    <article className={styleNames} onMouseEnter={()=>{setIsHover(true)}} onMouseLeave={() => {setIsHover(false)}}>
      {icon}
      <h3>{title}</h3>
      <p>{text}</p>
    </article>
  );
};

export default ServiceItem;