import Header from "./components/header/header";
import MainSection from "./components/main/main";
import Statistic from "./components/statistic/statistic";
import Services from "./components/services/services";
import HowToInfo from "./components/howToInfo/howToInfo";
import Appointment from "./components/appointment/appointment";
import Footer from "./components/footer/footer";
import { OrbitProgress } from "react-loading-indicators";
import { useState, useEffect } from "react";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleImagesLoad = () => {
      const images = document.querySelectorAll('img');
      console.log(images)
      let loadedCount = 0;

      images.forEach((image) => {
        if (image.complete) {
          loadedCount += 1;
        } else {
          image.addEventListener('load', () => {
            loadedCount += 1;
            if (loadedCount === images.length) {
              setLoading(false);
            }
          });
        }
      });

      if (loadedCount === images.length) {
        setLoading(false);
      }
    };

    handleImagesLoad();
  }, []);

  return (
    <>
      <Header />
      <main>
        <div className="loader" style={{display: loading ? 'flex' : 'none'}}>
          <OrbitProgress variant="disc" size="medium" text="" textColor="" />
        </div>
        <MainSection />
        <Statistic />
        <Services />
        <HowToInfo />
        <Appointment />
      </main>
      <Footer />
    </>
  );
}

export default App;
