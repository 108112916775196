import Button from "../button/button";
import styles from "./header.module.css";

const Header = () => {
  return (
    <header className={styles.header}>
        <div id="logo" className={styles.logo}>
          <h1 className={styles.ttl}>Фоколор</h1>
          <h2>FOCOLOR <span>SERVICE</span></h2>
        </div>
        <nav id="navigation">
          <ul>
            <li><a className={styles["nav-item"]} href="#main">Главная</a></li>
            <li><a className={styles["nav-item"]} href="#about">О Нас</a></li>
            <li><a className={styles["nav-item"]} href="#services">Услуги</a></li>
            <li><a className={styles["nav-item"]} href="#howToInfo">Обслуживание</a></li>
            <li><a className={styles["nav-item"]} href="#appointment">Запись</a></li>
          </ul>
        </nav>
        <div id="actions" className={styles.actions}>
          <Button text="+375 (29) 152-43-79" type="transparent" href="tel:+375291524379" />
        </div>
    </header>
  )
}

export default Header;