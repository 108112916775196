
import Button from "../button/button";
import styles from "./main.module.css";
import car from "./car.png";
import lights from "./lights.png";
// import roll from "./roll.png";

const MainSection = () => {
  return (
    <section id="main" className={styles.main}>
      <div className={styles.info}>
        <h2>Обслуживание и ремонт BMW & MINI</h2>
        <p>
        Фоколор-сервис – ваш надежный партнер по обслуживанию BMW и MINI. Наши специалисты гарантируют высокое качество услуг и безопасность вашего транспорта.
        </p>
        <Button text="Записаться" style={{width: "min-content"}} href="#appointment" type="filled" />
      </div>
      <div className={styles.back}>
        <span>FOCOLORSERVICE</span>
        <div className={styles.car}>
          <img src={car} alt="фоколор-сервис" />
        </div>
        {/* <div className={styles.roll}>
          <img src={roll} alt="engine" />
        </div> */}
        <div className={styles.lights}>
          <img src={lights} alt="lights" />
        </div>
      </div>
    </section>
  )
}

export default MainSection