import styles from "./howToInfo.module.css";

const HowToInfo = () => {
  return (
    <section id="howToInfo" className={styles.howto}>
      <div className={styles.heading}>
        <h2>Как обслужить ваш автомобиль</h2>
        <p>Наши четыре шага обслуживания автомобиля призваны сделать процесс максимально удобным для вас. Мы проводим всестороннюю диагностику, предлагаем эффективные решения и возвращаем ваш автомобиль в отличном состоянии. Ваша безопасность и удовлетворение - наш приоритет.</p>
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <h3><span>01</span> ЗАПИСЬ НА СЕРВИС</h3>
          <p>Используя форму ниже или по телефону - выберите удобный способ и укажите время, которое вам подходит.</p>
        </div>
        <div className={styles.item}>
          <h3><span>02</span> ДИАГНОСТИКА</h3>
          <p>Наши специалисты проведут проверку вашего автомобиля, выявят проблемы и расскажут о необходимых работах.</p>
        </div>
        <div className={styles.item}>
          <h3><span>03</span> РЕМОНТ И ОБСЛУЖИВАНИЕ</h3>
          <p>После вашего согласия на ремонт, наши мастера быстро и профессионально проведут необходимые работы.</p>
        </div>
        <div className={styles.item}>
          <h3><span>04</span> ВЫДАЧА АВТОМОБИЛЯ</h3>
          <p>Получите свой автомобиль обратно, исправный и готовый к новым дорожным приключениям!</p>
        </div>
      </div>
    </section>
  )
}

export default HowToInfo