import { useState } from "react";
import Button from "../button/button";
import emailjs from 'emailjs-com';
import styles from "./appointment.module.css";

const Appointment = () => {
  const [formData, setFormData] = useState({
    userName: '',
    userPhone: '',
    date: '',
    time: '',
    issue: ''
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      userName: formData.userName,
      userPhone: formData.userPhone,
      date: formData.date,
      time: formData.time,
      issue: formData.issue
    };

    emailjs.send(
      'service_7tamcrf', // Replace with your service ID
      'template_6sa1aap', // Replace with your template ID
      templateParams,
      'b7A7nE5l9nw-J8Md7' // Replace with your user ID
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    }, (err) => {
      console.log('FAILED...', err);
    });

    setFormData({ userName: '', userPhone: '', date: '', time: '', issue: ''});
  };


  return (
    <section id="appointment" className={styles.appointment}>
      <h2>Записаться онлайн</h2>
      <p>После того, как вы отправите форму, представитель перезвонит вам и уточнит информацию, необходимую для записи на прием.</p>
      <form>
        <div className={styles["form-element"]}>
          <label for="nameInp">ВАШЕ ИМЯ</label>
          <input id="nameInp" name="userName" value={formData.userName} onChange={handleChange} placeholder="Введите Ваше Имя" />
        </div>
        <div className={styles["form-element"]}>
          <label for="phoneInp">ВАШ ТЕЛЕФОН</label>
          <input id="phoneInp" name="userPhone" value={formData.userPhone} onChange={handleChange} placeholder="Введите Ваш Телефон" />
        </div>
        <div className={styles["form-element"]}>
          <label for="dateInp">ЖЕЛАЕМАЯ ДАТА</label>
          <input id="dateInp" name="date" value={formData.date} onChange={handleChange} placeholder="Введите Желаемую Дату"  />
        </div>
        <div className={styles["form-element"]}>
          <label for="timeInp">ЖЕЛАЕМОЕ ВРЕМЯ</label>
          <input id="timeInp" name="time" value={formData.time} onChange={handleChange} placeholder="Введите Желаемое Время" />
        </div>
        <div className={styles["form-element"]}>
          <label for="descInp">ОПИШИТЕ ПРОБЛЕМУ</label>
          <input id="descInp" name="issue" value={formData.issue} onChange={handleChange} placeholder="Опишите Вашу Проблему" />
        </div>
      </form>

      <Button text="Отправить" style={{width: "min-content"}} type="filled" press={handleSubmit} />
    </section>
  )
}

export default Appointment;