import styles from "./statistic.module.css";

const Statistic = () => {
  return (
    <section id="about" className={styles.statistic}>
      <div className={styles.item}>
        <span>10+</span>
        <div className={styles["item-text"]}>
          <p>Более 10 лет работы в сфере автосервиса.</p>
        </div>
      </div>
      <div className={styles.item}>
        <span>500+</span>
        <div className={styles["item-text"]}>
          <p>Довольных автовладельцев, доверящих свой выбор нам.</p>
        </div>
      </div>
      <div className={styles.item}>
        <span>1000+</span>
        <div className={styles["item-text"]}>
          <p>Успешно отремонтированных автомобилей.</p>
        </div>
      </div>
      <div className={styles.item}>
        <span>99%</span>
        <div className={styles["item-text"]}>
          <p>Рекомендаций от наших клиентов.</p>
        </div>
      </div>
    </section>
  )
}

export default Statistic;